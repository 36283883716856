a.titleLead.client-card {
    color: inherit !important;
}
.clients-list {
    li {
        cursor: default !important;
    }
    li:last-child {
        margin-bottom: 2.5rem !important;
    }
}
.sortBy {
    border: solid 1px #86868663;
    border-radius: 5px;
    padding: 4px;
    cursor: pointer;
}
.activeSort {
    color: #fff;
    border-radius: 0;
    background-color: $blue-primary;
    border: solid 2px #9db0ca;
}

.dropdown-orderBy {
    text-align: center;
    span {
        font-size: 12px;
        font-weight: 700;
    }
    .dropdown-item {
        width: 100%;
        padding: 5px !important;
    }
    .reset-orderBy {
        padding: 4px;
    }
}
.clients-filters-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 450px) {
        flex-direction: column-reverse;
        align-items: flex-start;
        .filter-on-clients button {
            padding-left: 0 !important;
        }
    }
    .filter-on-clients {
        margin-right: 5px !important;
        button {
            font-size: 11px !important;
        }
        svg {
            max-width: 12px;
            height: auto;
        }
    }    
}
.client-card {
    padding: 5px;
    .namesTitle {
        text-align: start;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .client-name {
            margin-bottom: 5px;
            display: flex;
            align-items: center;
            svg {
               min-width: 14px;
               height: auto;
               margin-top: -1px;
               margin-right: -2px;
            }
            h1 {
                margin: 0;
                text-align: start;
            }
            &:hover h1 {
                color: $blue-primary;
            }
        }
        .client-info-container {
            display: grid !important;
            grid-template-columns: repeat(4, 1fr);
            column-gap: 8px;
            @media (max-width: 600px) {
                grid-template-columns: repeat(3, 1fr);
                column-gap: 10px;
            }
            @media (max-width: 400px) {
                grid-template-columns: repeat(2, 1fr);
            } 
            .client-info {
                font-weight: 500;
                font-size: 13px;
                white-space: nowrap;
                svg  {
                    color: $blue-primary;
                    margin-right: -4px;
                }
            }   
        }
    }
    .btn-see-client {
        padding: 6px 10px;
        min-width: 95px;
        display: flex;
        justify-content: center;
        gap: 5px;
        align-items: center;
        font-size: 12px;
        border-radius: 15px;
        margin-top: 6px;
        @media (min-width: 501px) {
            margin-left: 1rem;
        }
        @media (max-width: 500px) {
            width: 150px;
        }
    }    
}

.client-content {
    .card-header {
        flex-wrap: nowrap;
        font-size: 16px;
    }
    .card-body.client-content-body {
        margin-left: 2rem;
        margin-right: 2rem;  
        hr {
            margin: 10px 0;
        } 
        .information h4 {
            font-size: 14px !important;
        }
        .count {
            background-color: #aaaaaa2c;
            color: inherit;
            font-weight: bold;
            font-size: 12px;
            padding: 0 4px;
            border-radius: 2px;
        }
        .list-group-item {
            background: #d8d8d80e !important;
        }
        .custom-card {
            transition: all .2s;
            &:hover {
                transform: translateY(-2px);
            }
        }
        @media (max-width: 550px) {
            width: auto !important;
            margin-left: 0 !important;
        }
    }
}

.container-data-grid {
    .MuiDataGrid-toolbarContainer {
        margin-bottom: 1rem;
    }
    .MuiDataGrid-selectedRowCount {
        display: none;
    }
    .MuiDataGrid-columnHeaders {
        background-color: #bbbbbb3f;
        div {
            font-weight: 600;
            font-size: 12px;
            svg, span {
                display: none;
            }
            
        }
    }
    .MuiDataGrid-withBorderColor {
        border: none !important;
    }
    .MuiDataGrid-row {
        border-bottom: solid 1px #bbbbbb63;
        .MuiDataGrid-cell:first-child {
            border-right: solid 1px #bbbbbb63 !important;
        }
    }
    .MuiDataGrid-footerContainer {
        justify-content: flex-start;
    }
    .MuiTablePagination-displayedRows {
        margin-bottom: 0;
    }
    .Mui-selected {
        background-color: transparent !important;
    }
    .MuiDataGrid-cell {
        text-overflow: ellipsis;
    }
    .MuiDataGrid-cell:hover {
        color: inherit !important;
    }
    .MuiDataGrid-cell:focus, .MuiDataGrid-columnHeader:focus {
        outline: none !important;
    }
    .MuiDataGrid-row .MuiDataGrid-cell:first-child {
        justify-content: center;
    }
}

.client-brokers {
    .MuiDataGrid-columnHeader:first-child {
        .MuiDataGrid-columnHeaderTitleContainer {
            justify-content: center !important;
        }
    }
}

.nav-net-worth-container {
    .nav-item-net-worth .nav-link {
        svg, span {
            margin-bottom: 2px !important;
        }
    }
}

.list-net-worth {
    padding: 0.50rem 3rem;

    .net-worth-item {
        h3 {
            font-size: 17px;
            display: flex;
            align-items: center;
            gap: 0.50rem;
        }
    
        .timeline {
            margin: 0;
            .flex-sm-row {
                display: none !important;
            }
            .timeline-item {
                padding-left: 2rem;
            }
            .net-worth-data {
                padding-bottom: 1.3rem;
                span {
                    font-size: 14px;
                    b {
                        margin-right: 2px;
                    }
                }
            }
        }
    }    
}
.form-add-net-worth {
    #input-year, #input-value, .btn {
        max-width: 350px !important;
    }
    .btn {
        justify-content: center;
    }
}

.client-processes {
    overflow-y: hidden !important;
    min-height: 100% !important;
    .board-wrapper, .client-process-board {
        max-width: 300px !important;
        min-width: 300px !important;
        height: 85% !important;
        @media (max-width: 650px) {
            min-width: 150px !important;
        }
        
    }
    .card-header {
        border-bottom: solid #6b6b6b18 2px;
    }
    .container-processes {
        display: flex;
        overflow: hidden;
        height: 100%;
        min-height: 100% !important;

        // estilos para a coluna board com os cards dos processos
        .client-process-board {
            .title-board {
                background-color: #f3f3f3;
                color: #4b4b4b;
                position: sticky !important;
                width: 100%;
                top: 0;
                z-index: 1;
                border-radius: 5px 5px 0 0;
                padding: 10px;
                text-transform: uppercase;
                font-weight: 600;
                font-size: 11px;
                user-select: none;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;
                span {
                    display: flex;
                    align-items: center;
                    margin-left: 3px;
                    .count {
                        background-color: #76767681;
                        padding: 4px;
                        border-radius: 50%;
                        margin: -2px 0 0 5px;
                        font-size: 9px;
                    }
                }
            }
            .cards-list {
                background-color: #f3f3f3;
                padding-bottom: 1rem;
                min-height: 80% !important;
            }
            .client-process-card {
                margin: 4px 10px !important;
                padding: 0.8rem;
                border-left: solid 3px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                transition: none !important;
                cursor: pointer;
                &:hover {
                    .card-title {
                        color: #558bc1;
                    }
                }
                .card-title {
                    font-size: 12px;
                    margin: 0;
                    font-weight: 500;
                }
                .card-info {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 6px;
                }
            }
            .active-card {
                border-top: solid 2px #5a94ceaf !important;
                border-right: solid 2px #5a94ceaf !important;
                border-bottom: solid 2px #5a94ceaf !important;
                .card-title {
                    color: #4f80b0;
                }
            }
            .client-process-card:first-child {
                margin-top: 0 !important;
            }       
        }
        
        // Dados do processo exibidos em tela
        .client-process-details {
            height: 85%;
            .card {
                .card-header {
                    padding: 6px 20px 10px 0;
                    font-size: 16px;
                    font-weight: 600;
                }
                .card-body {
                    b {
                        color: #737f91e3;
                        font-size: 13px;
                    }
                    div:not(:last-child) {
                        margin-bottom: 0.30rem  ;
                    }
                    a {
                        max-width: max-content;
                        svg {
                            margin-left: 5px;
                            margin-top: -2px;
                        }
                    }
                }
            }
        }
    }
}

.loading-refresh-data {
    animation: rotate360 1.5s;
    @keyframes rotate360 {
        from {
            transform: rotate(0);
        } to {
            transform: rotate(360deg);
        }
    }
}

.dark-layout {
    .client-content {
        .cards .card {
            .count {
                background-color: #ffffffcf;
                color: #242424;
            }
            &:hover {
                color: #d7d7d7;
                border-color: $blue-primary;
            }
        }
    }

    // estilos para a coluna board com os cards dos processos
    .client-process-board {
        .title-board {
            background-color: #3d465e !important;
            color: #fff !important;
        }
        .cards-list {
            background-color: #3d465e !important;
        }
    }
}