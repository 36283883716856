// utils
@import './variables/mixins';
@import './variables/breakpoints';
@import './variables/myVariables';
@import './variables/variables';

// partials
@import './styles/partials/timeline';

// Login
@import './styles/authentication/login';
@import './styles/authentication/forgotPassword';

// Leads
@import './styles/leads/titleLeads';
@import './styles/leads/leads';
@import './styles/leads/details/details';
@import './styles/leads/orderBy';
@import './styles/leads/pagination';

// Add New Lead
@import './styles/addLead/addLead.scss';

// Misc
@import './styles/misc/notAuthorized';
@import './styles/misc/error.scss';
@import './styles/misc/underMaintenance';

// Reutilizáveis
@import './styles/tags/tags';
@import './styles/leads/associate';

// Sidebar
@import './styles/sidebar/dateFilter';
@import './styles/sidebar/tags';
@import './styles/sidebar/sidebar';
@import './styles/sidebar/filterTags';
@import './styles/sidebar/filterBroker';

//  Financeiro
@import './styles/financeiro/parcelas';
@import './styles/financeiro/recibos';
@import './styles/financeiro/extratoSeguradoras';

// Clients
@import './styles/clients/clients';

// Demandas
@import './styles/demandas/index';
@import './styles/demandas/modal_create_card';
@import './styles/demandas/modal_process_data';
@import './styles/demandas/modal_policy';
@import './styles/demandas/form-comercial';

// Cadastrar demanda
@import './styles/cadastrar-demanda/cadastrarDemanda';

//Dashboard
@import './styles/dashboard/dashboard';

// New register
@import './styles/new-register/newRegister';

// Global
.logo-bravo {
    max-width: 120px;
    height: auto;
    width: 100%;

    @media (max-width: 767px) {
        max-width: 90px;
    }
}
em-emoji-picker {
  position: absolute;
  right: 0;
  z-index: 1;
}
.MuiButtonBase-root {
  color: $primary !important;
}
.max-content {
  width: max-content;
}
.invalid-input {
  border: solid 2px red;
  border-radius: 5px;
}
.bg-gradient-blue {
  background: linear-gradient(118deg, #1e73c7, rgba(30, 115, 199, 0.7)) !important;
  color: #fff !important;
  h5 {
    color: #fff !important;
  }
}
.alert {
  word-break: break-word !important;
}
.gap-25 {
  gap: .25rem;
}
.gap-50 {
  gap: .50rem;
}
.gap-75 {
  gap: .75rem;
}
.gap-1 {
  gap: 1rem;
}
.gap-2 {
  gap: 2rem;
}
.mb--10px {
  margin-bottom: -10px;
}
.mt--10px {
  margin-top: -10px;
}
.mr--10px {
  margin-right: -10px;
}
.ml--10px {
  margin-left: -10px;
}
.mb--1 {
  margin-bottom: -1rem;
}
.mt--1 {
  margin-top: -1rem;
}
.mr--1 {
  margin-right: -1rem;
}
.ml--1 {
  margin-left: -1rem;
}
.text-align-center {
  text-align: center;
}
.uppercase {
  text-transform: uppercase;
}
.font-8 {
  font-size: 8px;
}
.font-9 {
  font-size: 9px;
}
.font-10 {
  font-size: 10px;
}
.font-11 {
  font-size: 11px;
}
.font-12 {
  font-size: 12px;
}
.font-13 {
  font-size: 13px;
}
.font-14 {
  font-size: 14px;
}
.color-red {
  color: #c32020 !important;
}
.color-blue {
  color: $blue-primary !important;
}
.color-orange {
  color: orange !important;
}
.color-orangered {
  color: orangered !important;
}
.color-green {
  color: #2eab66 !important;
}
.hover-red {
  &:hover {
    color: $danger !important;
  }
}
.hover-blue {
  &:hover {
    color: $blue-primary !important;
  }
}
.mb-5px {
  margin-bottom: 5px;
}
.mb-4px {
  margin-bottom: 4px;
}
.mb-3px {
  margin-bottom: 3px;
}
.mb-2px {
  margin-bottom: 2px;
}
.mb-1px {
  margin-bottom: 1px;
}
.reset-order-btn {
  color: #ea5455 !important;
  background-color: transparent !important;
  border: none !important;
  &:hover {
      background-color: #ea545417 !important;
  }
}
.primereact-input-number {
  input {
    margin-right: 2px;
    padding: 3px 6px;
    outline-color: #1e73c7 !important;
    border: solid 1px #2929294f;
    border-radius: 3px;
  }
  button {
    border: none;
    padding: 4px 7px;
    background-color: #1e73c7;
    color: #fff;
    border-radius: 3px;
    margin: 0 1.5px;
  }
}
.filter-by-mui-btn button {
  outline: none !important;
  padding: 3px 6px;
}
// popover filtros
.MuiPopover-paper {
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
      width: 100%;
      outline: none;
  }
}
.loading-icon {
  color: #4080c1;
  animation: animate 2s infinite;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(720deg);
  }
}
.hover-scale {
  &:hover {
    transform: scale(1.1);
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
    -webkit-transform: scale(1.1);
  }
}
// filtros aplicados
.applied-filters {
  margin: 4px;
  font-size: 12px;
  padding-left: 4px;
  .badge {
    margin: 2px;
    text-align: center;
    cursor: default !important;
    span {
      margin: 5px 3px;
      opacity: .9;
    }
    svg {
      margin-left: 4px !important;
      margin-top: -1px;
      border-radius: 3px;
      min-width: 12px;
      height: auto;
      cursor: pointer !important;
      &:hover {
        background-color: #fff !important;
        color: rgb(230, 69, 69) !important;
      }
    }
  }
}
.MuiButtonBase-root {
  outline: none !important;
}

.label-for-input {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 3px;
}

.is-client {
  color: $orange-primary !important;
}
.isnt-client {
  color: $danger !important;
}

// Scroll Sidebar e Leads
.sidebar-menu-list, .overflowScroll {
  overflow-y: scroll !important;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0 !important
  }
}
//footer dark theme
.dark-layout {
  .primereact-input-number input {
    background-color: transparent !important;
    border: solid 1px #c7c7c74f !important;
    color: #c0c0c0 !important;
  }

  .apexcharts-text {
    fill: #a7a7a7c2 !important;
  }  
  
  // input currency do PersonalizedInput
  .css-1x51dt5-MuiInputBase-input-MuiInput-input {
    color: #b4b4b4 !important;
  }
  .reactstrap-input-select input {
    color: #c0c0c0 !important;
  }
  footer p {
    span:nth-child(1) {
      color: #dcdcdc93 !important;
    }
    span:nth-child(2n) {
      a strong {
        color: #dcdcdc93 !important;
        &:hover {
          color: #4081c1 !important;
        }
      }
    }
  }
}
.triangle {
  position: absolute;
  width: 0; 
  height: 0; 
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #5386e4b9;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  left: -3.515px;
  top: 0;
  transition: .1s all;
}
.square {
  position: absolute;
  width: 12px; 
  height: 12px; 
  background-color: #5386e4b9;
  font-size: 8px;
  font-weight: bold;
  color: #f3f3f3;
  border-bottom-right-radius: 4px;
  left: 0;
  top: 0;
  transition: .1s all;
  text-align: center;
}

.form-group-input-file {
  .btn {
    max-width: max-content;
    word-break: break-word;
    padding: 0.50rem 1rem;
  }
}