
.tela-login {
    // background: url('../../../images/imagesbravo/banners/Bravo_12.png');
    // background-position: center 25%;
    // background-repeat: no-repeat;
    // background-size: cover;

    background: url('../../../images/imagesbravo/banners/construcao_civil_amarelo.jpg');
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, 0.298); // Adiciona uma cor de fundo semi-transparente
    background-blend-mode: darken;
    background-size: cover;
    background-position: center left;

    .login-container {
        background-color:#ffffff;
    }

    .logo-img-title {
        max-width: 200px;
        height: auto;
        width: 180px;
    }

    .card-title-logo {
        text-align: center;
        cursor: default;
    }

    @media (max-width: 991.9px) {
        .login-container {
            background-color: transparent;

            .login-auth-area {
                background-color: #ffffff;
                padding: 2rem;
                border-radius: 10px;

                .logo-img-title{
                    width: 155px;
                }
            }
        }
    }
}





